<template>
  <div class="land-trust">
    <div class="quick-banner">
      <p>农资服务</p>
      <img src="../../assets/imgs/banner2.png" alt="" />
    </div>
    <div class="land-main">
      <div class="new-list">
        <!-- <ul class="news-tab">
          <li
            v-for="item of newTab"
            :key="item.code"
            :class="item.code == currentCode ? 'active' : ''"
            @click="tabHandle(item)"
          >
            {{ item.name }}
          </li>
        </ul> -->
        <div class="new-des">
          <ul>
            <li v-for="(item, i) in tableData" :key="i">
              <div class="img">
                <img src="../../assets/banner-3.png" />
              </div>
              <div class="des" @click="goDetail(item)">
                <h4>{{ item.name }}</h4>
                <div>{{ item.address }}</div>
              </div>
            </li>
          </ul>
          <div class="pages">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="prev, pager, next, sizes, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <card title="服务网点">
        <div class="map-area">
          <baiduMap :projectList="list" ref="bmMap" />
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card/index.vue";
import { mapGetters } from "vuex";
import BaiduMap from "../productionService/components/landTrustSubpage/baiduMap.vue";
import {
  getSupplyInfoOfMaterials,
  getAgriculturalAll,
} from "@/api/supplyDemand.js";
const newTab = [
  {
    code: 0,
    name: "全部",
  },
  {
    code: 1,
    name: "种子",
  },
  {
    code: 2,
    name: "农药",
  },
  {
    code: 3,
    name: "化肥",
  },
];

export default {
  components: {
    Card,
    BaiduMap,
  },
  computed: {
    ...mapGetters(["topSelectAreacode"]),
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 5,
      newTab,
      currentCode: 0,
      keyword: "",
      tableData: [],
      list: [
        {
          id: "0",
          name: "宣汉县县运营公司名称",
          lot: 107.95,
          lat: 31.4,
        },
        {
          id: "1",
          name: "宣汉县测试公司名称",
          lot: 108,
          lat: 31.5,
        },
      ],
    };
  },
  watch: {
    topSelectAreacode() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.getDataAll();
  },
  methods: {
    async getData() {
    //  console.log(this.topSelectAreacode,'查看这是什么')
      let params = {
        businessType: this.currentCode == 0 ? null : this.currentCode,
        pnum: this.currentPage,
        psize: this.pageSize,
        areaCode:this.topSelectAreacode
      };
      let res = await getSupplyInfoOfMaterials(params);
      if (res.dataNum > 0) {
        this.tableData = res.data;
        this.total = res.dataNum;
      }else{
        this.tableData = [];
        this.total = 0;
      }

    },

    // 获取农资服务地图信息
    async getDataAll() {
      let res = await getAgriculturalAll();
      if (!res || res.code != 0) {
        return;
      }

      this.list = [];
      res.data.forEach((ele) => {
        this.list.push({
          id: ele.id,
          name: ele.name,
          lot: ele.longitude,
          lat: ele.latitude,
        });
      });
    },

    goDetail(item) {
      this.$router.push({ name: "agriServiceDetail", params: { item: item } });
    },

    tabHandle(item) {
      this.currentCode = item.code;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.land-trust {
  background: #fff;
  li {
    list-style: none;
  }
}
.quick-banner {
  width: 1920px;
    margin: 0 auto;
  height: 180px;
  overflow: hidden;

  p {
    position: absolute;
    top: 40px;
    left: 20%;
    font-size: 32px;
    color: #fff;
  }
}

.land-main {
  width: 1472px;
  margin: 0 auto;

  .new-list {
    margin-top: 20px;
    .news-tab {
      color: #999;
      font-size: 16px;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      padding: 0px 0 16px 0px;
      position: relative;

      .input-with-select {
        position: absolute;
        right: 0;
        top: -10px;
        width: auto;
      }
      li {
        margin: 0px 20px 0px 0px;
        padding-left: 16px;
        font-size: 16px;
        font-weight: bold;

        &:hover {
          cursor: pointer;
          color: #333;
        }

        &.active {
          color: #333;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            top: 10px;
            left: 3px;
            background: #00a0e9;
            border-radius: 5px;
          }
        }
      }
    }

    .new-des {
      ul,
      li {
        padding: 0;
        margin: 0;
      }
      ul {
        padding-top: 20px;
      }
      ul li {
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px dashed #dcdcdc;

        .img {
          img {
            width: 120px;
            height: 100px;
          }
        }

        .des {
          padding-left: 20px;
          text-align: left;

          h4 {
            font-size: 24px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  .pages {
    margin-top: 16px;
    text-align: right;
  }

  .news-des {
    margin-bottom: 20px;
  }

  .map-area {
    margin: 20px 0;
    height: 500px;
  }
}
</style>

<style lang="less">
.land-trust .el-table th {
  background: #0668b3;
  color: #fff;
}
</style>
